import { IAtom, AtomSource, AtomType } from '@/domains/atom';
import { IContentProvider } from '@/domains/content-provider';
import { LevelEnum } from '../multileveltest';
import {
  UUID, DateTimeISO8601, PlayerSessionId, PlayerSessionStatus,
} from '@/domains/common';
import { IVideo } from '../multicontent';
import { IUploadedMedia } from '@/domains/multicontent/media';

// TODO: обновить статусы. Точно добавился deleted
export enum AssignmentStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  IN_PROCESS = 'in_process',
  EXPIRED = 'expired',
  FINISHED = 'finished',
  ASSIGNED_BY_ADMIN = 'assigned_by_admin',
  SELF_ASSIGNED = 'self_assigned',
  NO_DEADLINE = 'no_deadline',
  UNKNOWN = 'unknown',
}

export interface IAssignment {
  catalogueAtomMainId: IAtom['id']; // ID оригинального атома из сервиса catalogue
  assignmentMainId: number; // ID Главного назначения пользователя
  startDate: DateTimeISO8601; // Дата начала
  finishDate: DateTimeISO8601 | null; // Дата окончания
  selfAssigned: boolean; // Признак самоназначения
  onRecommendation: boolean; // Признак назначения по рекомендации
  assignmentUserId: number; // ID назначения пользователя
  catalogueAtomVersionId: IAtom['id']; // ID версии атома из сессии
  playerSessionId: number | null; // ID сессии Атома
  finishedAt: DateTimeISO8601 | null; // Дата завершения
  status: AssignmentStatus; // Статус сессии назначения
  sessionUpdatedAt: DateTimeISO8601; // Время последнего обновления сессии
  name: string; // Заголовок атома контента
  source: AtomSource; // Источник атома контента. уточнить, точно ли весь AtomSource
  atomType: AtomType; // Тип назначенного Атома
  trackProgress: {
    finishedStepsCount: number; // Количество пройденных шагов
    totalStepsCount: number; // Общее количество шагов
  }
}

export interface IAssignmentTrackContentProvider extends IContentProvider {}

export interface IAssignmentTrack {
  id: UUID;
  name: string;
  description: string;
  providers: IAssignmentTrackContentProvider[] | [];
}

export interface ITeamProgress {
  progress: number,
  progressDiff: number,
}

export enum SubordinateStatus {
  PENDING = 'pending',
  IN_PROCESS = 'in_process',
  COMPLETED = 'completed',
  NO_ASSIGNMENTS = 'no_assignments',
}

export interface ISubordinatesUser {
  userId: UUID,
  userFirstName: string,
  userLastName: string,
  totalProgress: number, // полный прогресс по всем программам в процентах
  changePerWeek: number, // изменение прогресса за неделю
  hotTracksCount: number, // количество непройденных программ с истекающим дедлайном
  hotTracksDeadline: number;
  userEmail: string;
}

export enum TestResultType {
  MULTI = 'multi',
  QUIZ = 'quiz',
}

export interface IAnalyticSubordinateUserBaseTestResult {
  trackName: string | null; // Название атома Курса из каталога, если тест был в шагом в нем, иначе null
  playerSessionId: PlayerSessionId; /** ID последеней по дате завершения сессии в статусе completed,
  для данного track - step_sessions */
  trackSessionId: number | null; // ID сессии Курса, если Тест был шагом в нем, иначе null
  stepId: UUID | null; // UUID Шага Курса, если Тест был в нем, иначе null
  assignmentSessionId: number; // ID Назначения пользователя
}

export interface IMultilevelTestResult {
  // полученные результаты по 5-бальной шкале
  level: string;
  maxLevel: LevelEnum;
}

export interface IAnalyticSubordinateUserMultilevelTestResult extends
 IAnalyticSubordinateUserBaseTestResult, IMultilevelTestResult {
  name: string; // Название атома-теста из каталога
}

export interface IQuizTestResult {
  atomContentId: UUID; // ID атома теста
  testPassed: boolean; // Признак - Пройден ли тест
  passedQuestions: number; // Кол-во правильных ответов
  totalQuestions: number; // Общее кол-во ответов
  userThreshold: number; // Процент правильных ответов (passed_questions / total_questions * 100)
  testThreshold: number; // Проходной порог теста в процентах, например от 0.00 до 100.00
  assignmentUserId: string; // ID Назначения пользователя
}

export interface IAnalyticSubordinateUserQuizTestResult extends
 IAnalyticSubordinateUserBaseTestResult, IQuizTestResult {
  testName: string; // Название атома-теста из каталога
}

export type IAnalyticSubordinateUserTestResult =
 | IAnalyticSubordinateUserMultilevelTestResult
 | IAnalyticSubordinateUserQuizTestResult;

export interface IAnalyticSubordinateUserAssignments {
  atomName: string; // Название Атома
  finishDate: DateTimeISO8601; // Дата окончания
  finishedAt: DateTimeISO8601; // Дата завершения прохождения пользователем
  progress: number; // Прогресс в процентах
  statuses: AssignmentStatus[]; // статусы для фильтрации
  assignmentStatus: AssignmentStatus; // статус назначения
  assignmentUserId: number; // ID назначения пользователя для Атома
  assignmentMainId: number; // ID главного назначения
  playerSessionId: string; // ID сессии программы
  atomType: AtomType; // Тип атома
  catalogueAtomMainId: UUID; // UUID атома-программы из каталога
}

export interface IAssignmentMultiContent {
  body: string;
  description: string;
  duration: number
  id: string
  title: string,
  providers: IContentProvider[],
  progress: {
    playerSessionId: PlayerSessionId,
    status: PlayerSessionStatus,
    progressMeta: string,
    videos: IVideo[],
  },
  media: [IUploadedMedia]
}

export * from './helpers/availabilityStepsButtons/types';
