
import { defineComponent, PropType } from 'vue';
import DialogComponent from '@/components/ui/DialogComponent';
import { getProviderImageUrl, IAssignmentTrackContentProvider } from '@/domains/assignment';
import { UUID } from '@/domains/common';
import { Banner } from '@/types/banner';

export default defineComponent({
  name: 'TrackMulticontentProvidersModal',
  components: {
    DialogComponent,
  },
  props: {
    providers: {
      type: Array as PropType<IAssignmentTrackContentProvider[]>,
      default: () => ([]),
    },
  },
  methods: {
    getProviderImageUrl(providerId: UUID): string {
      return getProviderImageUrl(providerId, '46x46');
    },
    open() {
      (this.$refs.banner as Banner).open();
    },
  },
});
