import { UUID } from '@/domains/common';
import { getImageUrl, ImageSize } from '@/helpers/gogha';

export default function getProviderImageUrl(providerId: UUID, size?: ImageSize) {
  return getImageUrl({
    uuid: providerId,
    type: 'provider',
    name: 'icon',
    size,
  });
}
