import transport from '@/services/api/lxp-assignment/transport';
import * as methods from '@/services/api/constants';
import {
  IUserAssignmentsGetResponseData,
  IUserAssignmentsRunRequestPayload,
  IUserAssignmentsRunResponseData,
  ITrackAssignResponseData,
  IAnalyticSubordinatesGetResponseData,
  IAnalyticSubordinateUserGetResponseData,
  IAnalyticSubordinatesGetRequestPayload,
  IMandatoryRecommendationsGetRequest,
  IMandatoryRecommendationsGetResponse,
  IFreeRecommendationsGetRequest,
  IFreeRecommendationsGetResponse,
  IAnalyticSubordinateUserTestsResults,
  IAnalyticsUserGetRequestPayload,
  IAssignmentBySessionGetRequest,
  IAssignmentBySessionGetData,
  IAssignRequest,
  IAssignResponseData,
  IUnassignResponseData,
  IUnassignRequest,
  IAssignmentRunRequest,
  IAssignmentRunResponseData,
} from './types';
import { UUID } from '@/domains/common';
import { AtomType } from '@/domains/atom';

interface IAssignmentGetParams {
  data?: {};
  params?: {
    atomType?: AtomType[],
  };
}
export const userAssignmentsGet = (
  { data = {}, params = {} }: IAssignmentGetParams = {},
): Promise<IUserAssignmentsGetResponseData> => transport({
  url: '/v3/my_assignments',
  method: methods.HTTP_GET,
  data,
  params,
});

/**
 * This method is deprecated use assignmentRun intead
 * @deprecated
 */
export const userAssignmentRun = (
  { assignmentUserId, data }:
    { assignmentUserId: number, data: IUserAssignmentsRunRequestPayload },
): Promise<IUserAssignmentsRunResponseData> => transport({
  url: `/v3/assignments/${assignmentUserId}/run`,
  method: methods.HTTP_POST,
  data,
});

/**
 * This method is deprecated use assign intead
 * @deprecated
 */
export const userTrackAssign = (
  { catalogueAtomId, data = { onRecommendation: false } }:
    { catalogueAtomId: UUID, data?: { onRecommendation: boolean} },
): Promise<ITrackAssignResponseData> => transport({
  url: `/v3/assignments/${catalogueAtomId}/assign`,
  method: methods.HTTP_POST,
  data,
});

/**
 * This method is deprecated use unassign intead
 * @deprecated
 */
export const userTrackUnassign = (assignmentUserId: number): Promise<{}> => transport({
  url: `/v3/assignments/${assignmentUserId}/unassign`,
  method: methods.HTTP_POST,
});

export const assign = ({
  paths: {
    catalogueAtomId,
  },
  data = {
    onRecommendation: false,
  },
}: IAssignRequest): Promise<IAssignResponseData> => transport({
  url: `/v3/assignments/${catalogueAtomId}/assign`,
  method: methods.HTTP_POST,
  data,
});

export const unassign = ({
  paths: {
    assignmentUserId,
  },
}: IUnassignRequest): Promise<IUnassignResponseData> => transport({
  url: `/v3/assignments/${assignmentUserId}/unassign`,
  method: methods.HTTP_POST,
});

export const assignmentRun = (
  {
    paths: {
      assignmentUserId,
    },
    data,
  }: IAssignmentRunRequest,
): Promise<IAssignmentRunResponseData> => transport({
  url: `/v3/assignments/${assignmentUserId}/run`,
  method: methods.HTTP_POST,
  data,
});

export const analyticSubordinatesGet = (
  payload: IAnalyticSubordinatesGetRequestPayload,
): Promise<IAnalyticSubordinatesGetResponseData> => transport({
  url: '/v3/analytics/subordinates',
  method: methods.HTTP_GET,
  params: {
    progressStatuses: payload.statuses,
  },
});

export async function analyticsUserGet(userId: UUID, params?: IAnalyticsUserGetRequestPayload):
  Promise<IAnalyticSubordinateUserGetResponseData> {
  const data = await transport({
    url: `/v3/analytics/users/${userId}`,
    method: methods.HTTP_GET,
    params,
  });

  const testResultTypeMap: Record<string, AtomType> = {
    multi: AtomType.MULTILEVELTEST,
    quiz: AtomType.QUIZ,
  };

  const renameTestResultType = (result: IAnalyticSubordinateUserTestsResults) => ({
    ...result,
    testResultType: testResultTypeMap[result.testResultType] ?? result.testResultType,
  });

  return {
    ...data,
    userTestResults: data.userTestResults.map(renameTestResultType),
  };
}

export const mandatoryRecommendationsGet = (
  { playerSessionId, playerType = 'multileveltest' }:
  {
    playerSessionId: IMandatoryRecommendationsGetRequest['playerSessionId'],
    playerType?: IMandatoryRecommendationsGetRequest['playerType']
  },
): Promise<IMandatoryRecommendationsGetResponse> => transport({
  url: `/v3/mandatory-recommendations/${playerSessionId}`,
  method: methods.HTTP_GET,
  params: {
    playerType,
  },
});

export const freeRecommendationsGet = (
  { playerSessionId, playerType = 'multileveltest' }:
    {
      playerSessionId: IFreeRecommendationsGetRequest['playerSessionId'],
      playerType?: IFreeRecommendationsGetRequest['playerType']
    },
): Promise<IFreeRecommendationsGetResponse> => transport({
  url: `/v3/free-recommendations/${playerSessionId}`,
  method: methods.HTTP_GET,
  params: {
    playerType,
  },
});

export const assignmentBySessionGet = async ({
  data = {},
  paths: {
    playerSessionId,
  },
  params,
  ...options
}: IAssignmentBySessionGetRequest): Promise<IAssignmentBySessionGetData> => transport({
  url: `/v3/assignments_by_session/${playerSessionId}`,
  method: methods.HTTP_GET,
  data,
  params,
  ...options,
});
