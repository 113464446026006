
import Vue from 'vue';
import { AnyFunction } from '@/types/helpers';

type Action = (value: boolean | null) => void;

interface IData {
  isVisible: boolean;
  resolvePromise: AnyFunction;
  rejectPromise: AnyFunction;
}

export default Vue.extend({
  name: 'DialogComponent',

  props: {
    dataTest: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    titleClass: {
      type: String,
      default: 'tt-text-subtitle',
    },

    dataTestTitle: {
      type: String,
      default: 'title',
    },

    width: {
      type: [String, Number],
      default: 'auto',
    },

    height: {
      type: [String, Number],
      default: 'auto',
    },

    maxWidth: {
      type: [String, Number],
      default: 'none',
    },

    maxHeight: {
      type: [String, Number],
      default: 'none',
    },

    overlay: {
      type: Boolean,
      default: true,
    },

    closable: {
      type: Boolean,
      default: true,
    },

    scrollable: {
      type: Boolean,
      default: false,
    },

    fullscreen: {
      type: Boolean,
      default: false,
    },

    hideOverlay: {
      type: Boolean,
      default: false,
    },

    contentClass: {
      type: String,
      default: '',
    },
  },

  data(): IData {
    return {
      isVisible: false,
      resolvePromise: () => {},
      rejectPromise: () => {},
    };
  },

  computed: {
    actions(): { close: Action } {
      return {
        close: (value: boolean | null) => {
          if (value === null) {
            return this.rejectPromise();
          }

          return this.resolvePromise(value);
        },
      };
    },

    classWrapper() {
      return {
        'rounded-lg': !this.fullscreen,
        'pa-4': !this.fullscreen,
      };
    },

    contentClasses() {
      return `${this.contentClass} box-shadow-light-new`;
    },
  },

  created() {
    this.resolvePromise = this.__close;
    this.rejectPromise = this.__close;
  },

  methods: {
    open(async: boolean) {
      // eslint-disable-next-line no-underscore-dangle
      this.__open();

      if (async) {
        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve;
          this.rejectPromise = reject;
        }).finally(() => {
          this.__close();
        });
      }

      return true;
    },

    close() {
      this.rejectPromise();
    },

    // eslint-disable-next-line no-underscore-dangle
    __open() {
      this.isVisible = true;
    },

    // eslint-disable-next-line no-underscore-dangle
    __close() {
      this.isVisible = false;
    },
  },
});
