export interface IButtonState {
  enabled: boolean;
  isLoading: boolean;
  showTooltip?: boolean;
  tooltipText?: string;
}

export enum ButtonsTypes {
  prev = 'prev',
  next = 'next',
}

export interface IButtonsState {
  [ButtonsTypes.prev]: IButtonState,
  [ButtonsTypes.next]: IButtonState,
  isShowNextStepLinearWarning: boolean
}
